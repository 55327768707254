import { render, staticRenderFns } from "./modifyPassword.vue?vue&type=template&id=170c8bc4&scoped=true&"
import script from "./modifyPassword.vue?vue&type=script&lang=js&"
export * from "./modifyPassword.vue?vue&type=script&lang=js&"
import style0 from "./modifyPassword.vue?vue&type=style&index=0&id=170c8bc4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "170c8bc4",
  null
  
)

export default component.exports