<template>
    <div
        class="home"
        v-loading="loading"
    >
        <div class="white-bg">
            <div class="baccount-tit">
                <h3 class="g-title">{{ title }}</h3>
            </div>
            <div class="zpanle modifyp-panle">
                <div class="account-hd">
                    <div class="account-cnt">
                        <img
                            class="top-icon-img"
                            :src="$img.lock"
                        />
                        <p class="accont-slogin">{{ title }}</p>
                    </div>
                </div>
                <div class="panner-bd modifypd">
                    <el-form
                        ref="accountForm"
                        :model="accountForm"
                        class="g-form"
                        :rules="formRules"
                        style="width:370px;"
                    >
                        <!--  只有修改支付密码时显示手机验证 -->
                        <el-form-item
                            prop="mobile"
                            v-if="type == 2"
                        >
                            <el-input
                                disabled
                                type="tel"
                                v-model="accountForm.mobile"
                                :placeholder="userInfo.info.mobile || '请先绑定手机号'"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>

                        <!--  只有修改支付密码时显示手机验证码 -->
                        <el-form-item
                            prop="code"
                            class="yzm-item"
                            v-if="type == 2"
                        >
                            <el-input
                                type="text"
                                v-model="accountForm.code"
                                placeholder="请输入验证码"
                                auto-complete="off"
                            ></el-input>
                            <el-button
                                @click="getMobileCode"
                                class="dx-code"
                            >{{time==0?'获取验证码':time+'秒后获取'}}</el-button>
                            <el-link
                                :underline="false"
                                class="no-receivetip"
                                @click="handleClickkefu = true"
                                style="font-size:14px;"
                            >无法收到验证码？</el-link>
                        </el-form-item>

                        <el-form-item prop="password">
                            <el-input
                                type="password"
                                v-model="accountForm.password"
                                placeholder="请输入新密码"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>

                        <el-form-item prop="repwd">
                            <el-input
                                type="password"
                                v-model="accountForm.repwd"
                                placeholder="请确认新密码"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>

                        <el-form-item class="btn-wrap ml-b">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="handleSubmit()"
                                >提交</el-button>
                            </div>
                        </el-form-item>

                        <el-form-item class="btn-wrap back-wrap mr-b">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="$router.back(-1);"
                                >返回</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <!-- 收不到验证码弹窗 -->
        <el-dialog
            class="blue-top-border buy-dialog"
            title="客服热线"
            :visible.sync="handleClickkefu"
            width="400px"
            center
        >
            <div class="dial-txt">
                <p>
                    若手不到短信验证码，请拨打客服热线
                    <br />
                    <a
                        href="javascript:void(0)"
                        v-if="websitInfo"
                    >{{ websitInfo.webkitData.site_phone }}</a>
                </p>
            </div>
            <span
                slot="footer"
                class="dialog-footer news-back-wrap"
            >
                <el-button @click="handleClickkefu = false">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "validEmail",
    metaInfo() {
        return {
            title: "修改支付密码 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        /* 验证两次密码输入是否相同  */
        var validatePass = (rule, value, callback) => {
            // 新密码
            if (!value) {
                callback(new Error("请输入新密码"));
            } else if (
                value.toString().length < 6 ||
                value.toString().length > 18
            ) {
                callback(new Error("密码长度为6 - 18个字符"));
            } else {
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            /* 再次验证密码输入是否相等 */
            // 再次输入新密码进行验证
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.accountForm.password) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            handleClickkefu: false, // 是否显示收不到验证码弹窗提示客服
            loading: false,
            time: 0, // 倒计时秒数
            timer: null, // 倒计时时间对象
            accountForm: {
                // 此处需要对字段进行核实=======
                password: "", // 新密码
                repwd: "", // 再次确认密码
                validPassword: "", // 确认新密码
                mobile: "",
                code: "",
                type: 2,
                action: 2
            },
            formRules: {//修改密码或设置支付密码
                password: { required: true, validator: validatePass, trigger: "blur" },
                repwd: { required: true, validator: validatePass2, trigger: "blur" }
            },
            formRules2: {//支付密码找回
                mobile: { required: true, trigger: "blur" },
                code: { required: true, trigger: "blur" },
                password: { required: true, validator: validatePass, trigger: "blur" },
                repwd: { required: true, validator: validatePass2, trigger: "blur" }
            }
        };
    },
    computed: {
        ...mapState(["userInfo", "websitInfo"])
    },
    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo", "loginOut"]),//刷新用户数据方法
        handleSubmit() {
            // 提交修改 参数待调整=============================
            let _this = this;
            if (_this.$route.query.type == 1) {//支付密码
                _this.rules = _this.formRules;
                _this.$refs["accountForm"].validate((valid, validInfoObj) => {
                    if (valid) {
                        _this.post(
                            "/user/user/set_paypassword",
                            {
                                password: _this.accountForm.password
                            },
                            res => {
                                if (res.code == 200) {
                                    _this.$message.success('设置成功');
                                    _this.ajaxUserInfo();
                                    let currentPageUrl = sessionStorage.getItem('currentPageUrl');
                                    if (currentPageUrl) {
                                        _this.$router.push(currentPageUrl);
                                    } else {
                                        _this.$router.push("/user/setting");
                                    }
                                } else {
                                    _this.$message.error(res.msg);
                                    return;
                                }
                            }
                        );
                    }
                });
            } else if (_this.$route.query.type == 2) {//修改支付密码
                _this.rules = _this.formRules2;
                _this.accountForm.mobile = _this.userInfo.info.mobile;
                _this.$refs["accountForm"].validate((valid, validInfoObj) => {
                    if (valid) {
                        _this.post(
                            "/user/index/update_paypassword",
                            {
                                mobile: _this.accountForm.mobile,
                                code: _this.accountForm.code,
                                password: _this.accountForm.password
                            },
                            res => {
                                if (res.code == 200) {
                                    _this.$message.success('修改成功');
                                    _this.ajaxUserInfo();
                                    _this.$router.push("/user/setting");
                                } else {
                                    _this.$message.error(res.msg);
                                    return;
                                }
                            }
                        );
                    }
                });
            } else {//修改登录密码
                _this.rules = _this.formRules;
                _this.$refs["accountForm"].validate((valid, validInfoObj) => {
                    if (valid) {
                        _this.post(
                            "/user/user/update_password",
                            {
                                password: _this.accountForm.password
                            },
                            res => {
                                if (res.code == 200) {
                                    _this.$message.success('修改成功，请重新登录');
                                    _this.ajaxUserInfo();
                                    _this.loginOut();//退出登录
                                    _this.$router.push('/login/index?type=login');
                                } else {
                                    _this.$message.error(res.msg);
                                    return;
                                }
                            }
                        );
                    }
                });
            }
        },
        getMobileCode() {
            let _this = this;
            if (_this.time == 0) {
                _this.accountForm.mobile = _this.userInfo.info.mobile; // 此处待后台调整，现在后台发送验证码是到老手机上面，应该调整为发送到新手机号上面进行验证绑定
                _this.post("/user/index/get_sms_code", { mobile: _this.accountForm.mobile, template: 'change_pay_password' }, res => {
                    if (200 == res.code) {
                        _this.time = 60;
                        _this.startTimer();
                        _this.$message({
                            type: "success",
                            message: res.msg,
                            duration: 1500
                        });
                    } else {
                        _this.$message.error(res.msg);
                        return;
                    }
                });
            }
        },
        startTimer() {
            // 倒计时
            let _this = this;
            if (!_this.timer) {
                _this.timer = setInterval(() => {
                    if (_this.time > 0) {
                        _this.time--;
                        sessionStorage.setItem("bindPasswordTime", _this.time);
                    } else {
                        clearInterval(_this.timer);
                        _this.timer = null;
                        sessionStorage.removeItem("bindPasswordTime");
                    }
                }, 1000);
            }
        }
    },
    created() {
        this.title = this.$route.query.title;
        this.type = this.$route.query.type || ""; // 当前页面的类型，1为设置支付码，2为修改支付密，默认为修改登录密码
        let _time = sessionStorage.getItem("bindPasswordTime");
        if (_time) {
            this.time = _time;
            this.startTimer();
        }
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/account.less";
.no-receivetip {
    position: absolute;
    top: 0;
    right: -150px;
    font-size: 14px;
    color: #ccc;
}
</style>


